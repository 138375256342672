.Application{
    background-color: var(--subBackgroundcolor);
    color: var(--mainFontcolor);
}

/* pc */
.WorkSub_PC{
    margin-top: 100px;
}

.WorkSub_PC .Applications_PCP .Page{
    width: 360px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 20px;
}

.WorkSub_PC .Applications_PCP .Page_IMG{
    width: 320px;
    height: 240px;
    overflow-y: hidden;
    background-color: var(--subBackgroundcolor);
    border: 1px solid var(--mainFontcolor);
    position: relative;
}

.WorkSub_PC .Applications_PCP .Page_IMG img{
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
}

.WorkSub_PC .Applications_PCP .Page_Context{
    padding-left: 10px;
}

.WorkSub_PC .Content_PCP .Page{
    width: 370px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 20px;
}

.WorkSub_PC .Content_PCP .Page_IMG{
    width: 330px;
    height: 440px;
    overflow-y: hidden;
    background-color: var(--subBackgroundDarkcolor);
    border: 1px solid var(--mainFontcolor);
}

.WorkSub_PC .Content_PCP .Page_IMG img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 90%;
}

.WorkSub_PC .Content_PCP .Page_Context{
    padding-left: 10px;
}

/* Tab */
.WorkSub_Tab{
    margin-top: 100px;
}

.WorkSub_Tab .Application_TabP .Page{
    width: 320px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 20px;
}

.WorkSub_Tab .Application_TabP .Page_IMG{
    width: 280px;
    height: 210px;
    background-color: var(--subBackgroundcolor);
    border: 1px solid var(--mainFontcolor);
    position: relative;
    overflow-y: hidden;
}

.WorkSub_Tab .Application_TabP .Page_IMG img{
    object-fit: contain;
    position: absolute;
    top: 0; left: 0;
}

.WorkSub_Tab .Application_TabP .Page_Context{
    padding-left: 10px;
}

.WorkSub_Tab .Content_TabP .Page{
    width: 340px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 20px;
}

.WorkSub_Tab .Content_TabP .Page_IMG{
    width: 300px;
    height: 400px;
    background-color: var(--subBackgroundDarkcolor);
    border: 1px solid var(--mainFontcolor);
    overflow-y: hidden;
}

.WorkSub_Tab .Content_TabP .Page_IMG img{
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.WorkSub_Tab .Content_TabP .Page_Context{
    padding-left: 10px;
}

/* MB */
.WorkSub_MB{
    margin-top: 80px;
}

.WorkSub_MB .Application_MBP .WorkSub_MBP{
    padding: 0 30px;
}

.WorkSub_MB .Application_MBP .Page{
    margin: 0 auto;
    padding: 20px;
    border-radius: 20px;
}

.WorkSub_MB .Application_MBP .Page_IMG{
    background-color: var(--subBackgroundcolor);
    border: 1px solid var(--mainFontcolor);
    /* width: calc(100vw - 100px); */
    height: calc((100vw - 100px) * 0.75 );
    overflow-y: hidden;
    position: relative;
}

.WorkSub_MB .Application_MBP .Page_IMG img{
    object-fit: contain;
    position: absolute;
    top : 0; left: 0;
}

.WorkSub_MB .Application_MBP .Page_Context{
    padding-left: 10px;
}

.WorkSub_MB .Content_MBP .Content_MBP{
    padding: 0 30px;
}

.WorkSub_MB .Content_MBP .Page{
    margin: 0 auto;
    padding: 20px;
    border-radius: 20px;
}

.WorkSub_MB .Content_MBP .Page_IMG{
    background-color: var(--subBackgroundDarkcolor);
    border: 1px solid var(--mainFontcolor);
    /* width: calc(100vw - 100px); */
    height: calc((100vw - 100px) * 4 / 3 );
    overflow-y: hidden;
}

.WorkSub_MB .Content_MBP .Page_IMG img{
    object-fit: contain;
    width : 100%;
    height : 100%;
}

.WorkSub_MB .Content_MBP .Page_Context{
    padding-left: 10px;
}

/* 웹앱 이미지창 공통 */

.Application .Page > * {
    pointer-events: none;
}

.Application .Page > .PageIMGDetail {
    pointer-events: auto;
}

.Application .Page_IMG{
    position: relative;
}

.Application .Page_IMG::after{
    content: "See More...";
    display: flex;
    width: 100%; height: 100%;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(0,0,0,0.5);
    font-size: 20px;
    font-weight: lighter;
    position: absolute;
    top: 0; left: 0;
    z-index: 996;
    opacity: 100%;
}

.Application .WorkSub_PC .Page_IMG::after{
    opacity: 0;
    transition: opacity 0.5s;
}

.Application .WorkSub_PC .Page:hover .Page_IMG::after{
    opacity: 100%;
}

/* 상세창 공통 */
.Application .PageIMGDetail{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 998;
    background-color: rgba(0,0,0,0.7);
}

.Application .PageIMGDetail img{
    width: 90%;
    object-fit: contain;
    margin: auto;
}

.Application .PageIMGDetail.on{
    display: flex;
}

.Application .PageIMGDetail .PageIMGDetailLayer{
    height: 600px;
    padding: 0;
}

.Application .PageIMGDetail .PageIMGDetailLayer .PageIMG_IMG{
    background-color: var(--subBackgroundcolor);
    border-radius: 20px 0 0 20px;
    padding: 40px 0;
    width: 60%;
    overflow-y: auto;
}

.Application .PageIMGDetail .PageIMGDetailLayer .PageIMG_Context{
    background-color: var(--mainBackgroundcolor);
    border-radius: 0 20px 20px 0;
    overflow-y: auto;
    padding: 30px 40px;
}
/* 상세창 pc */
.Application .WorkSub_PC .PageIMGDetail .PageIMGDetailLayer{
    width: 1140px;
}

/* 상세창 모바일 */
.Application .WorkSub_MB .PageIMGDetail .PageIMGDetailLayer{
    height: 80vh;
    padding: 30px;
}

.Application .WorkSub_MB .PageIMGDetail .PageIMGDetailLayer .PageIMG_IMG{
    background-color: var(--subBackgroundcolor);
    border-radius: 20px 20px 0 0;
    height: 42vh;
    width: 100%;
}

.Application .WorkSub_MB  .PageIMGDetail .PageIMGDetailLayer .PageIMG_Context{
    background-color: var(--mainBackgroundcolor);
    border-radius: 0 0 20px 20px;
}

@media only screen and (max-width: 380px) {
    .WorkSub_MB .Page_IMG{
        height: calc((100vw - 100px) * 1.25 );
        object-fit: contain;
    }

    .Application .PageIMGDetail{
        height: calc( 100vh * 10 / 6 );
    }

    .Application .WorkSub_MB .PageIMGDetail .PageIMGDetailLayer{
        height: calc(80vh * 10 / 6);
    }
    .Application .WorkSub_MB .PageIMGDetail .PageIMGDetailLayer .PageIMG_IMG{
        height: 70vh;
    }    
}

@media only screen and (max-width: 767px) {
    .WorkSub_PC{
        display: none ;
    }
    .WorkSub_Tab{
        display: none;
    }
    .WorkSub_MB {
        display: flex;
    }
}

@media only screen and (min-width : 768px) and (max-width : 1199px) {
    .WorkSub_PC{
        display: none ;
    }
    .WorkSub_Tab{
        display: flex;
    }
    .WorkSub_MB {
        display: none;
    }
}

@media only screen and (min-width: 1200px) {
    .WorkSub_PC{
        display: flex ;
    }
    .WorkSub_Tab{
        display: none;
    }
    .WorkSub_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .WorkSub_PC .PageIMGDetail{
        height: calc(100vh / 12 * 10 );
    }
}

@media only screen and (min-width: 2561px){
    .WorkSub_PC .PageIMGDetail{
        height: calc(100vh / 14 * 10 );

    }
}