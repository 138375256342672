.Footer{
    font-family: 'ONE-Mobile-Title';
    background-color: var(--mainDurationColor);
    color: #ffffff;
}

.FMSite{
    position: relative;
}

.FMSite ul{
    position: absolute;
    top : calc(100% + 10px);
    left: auto;
    right: -5px;
    padding: 10px;
    background-color: var(--mainBackgroundcolor);
    color: var(--mainFontcolor);
    border: 2px solid var(--mainWidgetcolor2);
}

/* PC */
.Footer_PC > div{
    width: 90%;
    font-size: 16px;
}

.Footer_PC .Footer_PC-Top{
    margin: 50px 0;
}

.Footer_PC .Footer_PC-Top .FPT-Logo img{
    height: 70px;
    object-fit: contain;
}

.Footer_PC .Footer_PC-Top .FPT-Upper{
    padding: 30px;
    border-radius: 100%;
    border: 1px solid white;
    transition : background-color 1s;
}

.Footer_PC .Footer_PC-Top .FPT-Upper:hover{
    background-color: rgba(255,255,255,0.2);
}

.Footer_PC .Footer_PC-Menu{
    padding: 0 20px 20px 20px;
    border-bottom: 1px solid white;
    margin-bottom: 20px;
    transition: opacity 1s;
}

.Footer_PC .Footer_PC-Menu li:hover{
    opacity: 80%;
}

.Footer_PC .FPT-Menu{
    border-left: 0.5px solid white;
    border-right: 0.5px solid white;
}

.Footer_PC .FPT-Menu > li {
    border-left: 0.5px solid white;
    border-right: 0.5px solid white;
    width: 120px;
}

.Footer_PC .FPT-CopyRight {
    width: 88%;
    margin-bottom: 60px;
}

/* Mobile */
.Footer_MB > div{
    width: 90%;
    font-size: 16px;
}

.Footer_MB .Footer_MB-Top{
    margin: 50px 0 70px 0;
}

.Footer_PC .Footer_MB-Top .FPT-Logo img{
    height: 70px;
    object-fit: contain;
}

.Footer_MB .Footer_MB-Top .FPT-Upper{
    /* padding: 30px; */
    width: 80px;
    height: 80px;
    border-radius: 100%;
    border: 1px solid white;
    transition : background-color 1s;
    justify-content: center;
    align-items: center;
}

.Footer_MB .Footer_MB-Top .FPT-Upper:hover{
    background-color: rgba(255,255,255,0.2);
}

.Footer_MB .Footer_MB-Menu{
    padding: 0 20px 20px 20px;
    border-bottom: 1px solid white;
    margin-bottom: 20px;
    transition: opacity 1s;
}

.Footer_MB .Footer_MB-Menu li:hover{
    opacity: 80%;
}

.Footer_MB .FPT-Menu{
    border-left: 0.5px solid white;
    border-right: 0.5px solid white;
}

.Footer_MB .FPT-Menu > li {
    border-left: 0.5px solid white;
    border-right: 0.5px solid white;
    width: 120px;
}

.Footer_MB .FPT-CopyRight {
    width: 88%;
    margin-bottom: 60px;
}

@media only screen and (max-width: 380px) {
    .Footer_MB .FPT-Logo{
        zoom: 0.6;
    }
}

@media only screen and (max-width: 900px) {
    .Footer_PC{
        display: none ;
    }
    .Footer_MB {
        display: flex;
    }
}

@media only screen and (min-width: 901px) and (max-width : 1199px) {
    .Footer_PC{
        display: flex ;
        zoom: 0.7;
    }
    .Footer_MB {
        display: none;
    }
}
  
@media only screen and (min-width: 1200px) {
    .Footer_PC{
        display: flex ;
    }
    .Footer_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .Footer_PC{
        zoom: 1.2;
    }
}

@media only screen and (min-width: 2561px){
    .Footer_PC{
        zoom: 1.4;
    }
}
  