.Contact{
    height: calc(100vh - 100px);
    background-color: var(--mainBackgroundcolor);
    color: var(--mainFontcolor);
}

/* pc */
.Contact_PC{
    min-height: calc(100vh - 100px);
}

.Contact_PC > div{
    margin-bottom: 100px;
}

.Contact_PC-left{
    margin-top: 40px;
    width: 1000px;
}

.Contact_PC-left > div{
    width: 400px;
    font-size: 32px;
    font-weight: bold;
    padding-left: 26px;
}

.Contact_PC-left > div:first-child{
    font-size: 72px;
    padding-left: 0px;
}

/* tab */
.Contact_Tab{
    padding: 40px 30px 100px 30px;
}

.Contact_Tab-top > div{
    font-size: 24px;
    font-weight: bold;
    padding-left: 16px;
}

.Contact_Tab-top > div:first-child{
    font-size: 56px;
    padding-left: 0px;
}

/* mb */
.Contact_MB{
    padding: 0 30px;
}

.Contact_MB-top > div{
    font-size: 24px;
    font-weight: bold;
    padding-left: 16px;
}

.Contact_MB-top > div:first-child{
    font-size: 56px;
    padding-left: 0px;
}

@media only screen and (max-width: 380px) {
    .Contact_MB{
        zoom: 0.6;
    }
}

@media only screen and (max-width: 767px) {
    .Contact_PC{
        display: none ;
    }
    .Contact_Tab{
        display: none;
    }
    .Contact_MB {
        display: flex;
    }
}

@media only screen and (min-width : 768px) and (max-width : 1199px) {
    .Contact_PC{
        display: none ;
    }
    .Contact_Tab{
        display: flex;
    }
    .Contact_MB {
        display: none;
    }
}

@media only screen and (min-width: 1200px) {
    .Contact_PC{
        display: flex ;
    }
    .Contact_Tab{
        display: none;
    }
    .Contact_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .Contact_PC{
        zoom: 1.2;
    }
}

@media only screen and (min-width: 2561px){
    .Contact_PC{
        zoom: 1.4;
    }
}