.Home{
    font-family : 'ONE-Mobile-Title';
    background-color: var(--mainBackgroundcolor);
    color: var(--mainFontcolor);
}

/* pc */
.Home_PC-Upper{
    padding: 30px 0;
    min-height: calc((100vh - 100px) / 5 * 3);
}

.Home_PC-Bottom{
    min-height: calc((100vh - 100px) / 5 * 2);
    background-color: var(--mainWidgetcolor);
    border-top : 6px solid white;
}

.Home_PC-Bottom a{
    min-height: 180px;
    margin: 20px 10px;
    border: 2px solid white;
    color: var(--mainWidgetcolor2);
    font-size: 18px;
}

.Home_PC-Bottom a:first-child{
    margin-left: 20px;
}

.Home_PC-Bottom a:last-child{
    margin-right: 20px;
}

.Home_PC-Bottom a:hover{
    border: 4px solid white;
    color: var(--mainFontcolor);
}
.Home_PC-left > div:first-child {
    width: 260px;
    height: 260px;
    border-radius: 200px;
    background-color: var(--mainWidgetcolor);
}
.Home_PC-left img {
    width: 180px;
}

.Home_PC-right > div{
    font-size: 24px;
    font-weight: bold;
    padding-left: 10px;
}
.Home_PC-right > div:first-child{
    font-size: 54px;
    padding-left: 0px;
    padding-bottom: 6px;
}

.Home_PC_Sns-balls a{
    padding: 20px;
    border-radius: 100%;
    background-color: var(--mainWidgetcolor);
    font-size: 32px;
}
.Home_PC_Sns-balls a:hover{
    border: 4px solid var(--mainWidgetcolor2);
}
.Home_PC_Sns-balls a img{
    width: 40px;
    height: 40px;
}

/* Tab */
.Home_Tab-Upper{
    padding: 30px;
}

.Home_Tab-Bottom{
    background-color: var(--mainWidgetcolor);
    border-top : 6px solid white;
}

.Home_Tab-Bottom a{
    min-height: 180px;
    margin: 10px 20px;
    border: 2px solid white;
    color: var(--mainWidgetcolor2);
    font-size: 18px;
}

.Home_Tab-Bottom a:first-child{
    margin-top: 30px;
}

.Home_Tab-Bottom a:last-child{
    margin-bottom: 40px;
}

.Home_Tab-Bottom a:hover{
    border: 4px solid white;
    color: var(--mainFontcolor);
}

.Home_Tab-left > div:first-child {
    width: 220px;
    height: 220px;
    border-radius: 200px;
    background-color: var(--mainWidgetcolor);
}
.Home_Tab-left img {
    width: 140px;
}

.Home_Tab-right > div{
    font-size: 20px;
    font-weight: bold;
}
.Home_Tab-right > div:first-child{
    font-size: 42px;
    padding-left: 0px;
    padding-bottom: 6px;
}

.Home_Tab_Sns-balls a{
    padding: 20px;
    border-radius: 100%;
    background-color: var(--mainWidgetcolor);
    font-size: 32px;
}
.Home_Tab_Sns-balls a img{
    width: 50px;
    height: 50px;
}

/* mb */
.Home_MB-Upper{
    padding: 30px 30px 60px 30px;
}

.Home_MB-Bottom{
    background-color: var(--mainWidgetcolor);
    border-top : 6px solid white;
}

.Home_MB-Bottom a{
    min-height: 180px;
    margin: 10px 20px;
    border: 2px solid white;
    color: var(--mainWidgetcolor2);
    font-size: 18px;
}

.Home_MB-Bottom a:first-child{
    margin-top: 30px;
}

.Home_MB-Bottom a:last-child{
    margin-bottom: 40px;
}

.Home_MB-Bottom a:hover{
    border: 4px solid white;
    color: var(--mainFontcolor);
}

.Home_MB-left > div:first-child {
    width: 280px;
    height: 280px;
    border-radius: 200px;
    background-color: var(--mainWidgetcolor);
}
.Home_MB-left img {
    width: 180px;
}

.Home_MB-right > div{
    font-size: 24px;
    font-weight: bold;
}
.Home_MB-right > div:first-child{
    font-size: 48px;
    padding-left: 0px;
    padding-bottom: 6px;
}

.Home_MB_Sns-balls a{
    padding: 20px;
    border-radius: 100%;
    background-color: var(--mainBackgroundcolor);
    font-size: 32px;
}
.Home_MB_Sns-balls a img{
    width: 50px;
    height: 50px;
}

@media only screen and (max-width: 380px) {
    .Home_MB{
        zoom: 0.6;
    }
}

@media only screen and (max-width: 767px) {
    .Home_PC{
        display: none ;
    }
    .Home_Tab{
        display: none;
    }
    .Home_MB {
        display: flex;
    }
}

@media only screen and (min-width : 768px) and (max-width : 1199px) {
    .Home_PC{
        display: none ;
    }
    .Home_Tab{
        display: flex;
    }
    .Home_MB {
        display: none;
    }
}

@media only screen and (min-width: 1200px) {
    .Home_PC{
        display: flex ;
    }
    .Home_Tab{
        display: none;
    }
    .Home_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .Home_PC{
        zoom: 1.2;
    }
}

@media only screen and (min-width: 2561px){
    .Home_PC{
        zoom: 1.4;
    }
}