.Works{
    font-family : 'ONE-Mobile-Title';
    background-color: var(--mainBackgroundcolor);
    color: var(--mainFontcolor);
}

/* pc */
.Works_PC-banner{
    height: 300px;
}

.Works_PC-banner-left{
    font-size: 64px;
    font-weight: bold;
}

.Works_PC-banner-right {
    font-size: 32px;
}

.Works_PC-banner-tabs-pages{
    border-top-width: 2px;
    border-top-color: var(--subBackgroundDarkcolor);
}

/* Tab */
.Works_Tab-banner{
    height: 300px;
}

.Works_Tab-banner-left{
    font-size: 64px;
    font-weight: bold;
}

.Works_Tab-banner-right {
    font-size: 32px;
}

.Works_Tab-banner-tabs-pages{
    border-top-width: 2px;
    border-top-color: var(--subBackgroundDarkcolor);
}

/* mobile */
.Works_MB-banner{
    padding: 50px 0 30px 30px;
}

.Works_MB-banner-top{
    font-size: 48px;
    font-weight: bold;
}

.Works_MB-banner-bottom{
    padding-left: 8px;
    font-size: 20px;
}

/* 탭메뉴 공통 css */
.Works-banner-tabs-menu{
    padding-right: 30px;
}

.Works-banner-tabs-menu{
    font-size: 18px;
}

.Works-banner-tabs-menu > li{
    width: 180px;
    height: 50px;
}

.Works-banner-tabs-menu > li.Choosed{
    color: var(--mainFontcolor);
    background-color: var(--subBackgroundcolor);
    border: 2px solid var(--subBackgroundDarkcolor);
    border-bottom : none;
    position: relative;
}

.Works-banner-tabs-menu > li.Choosed::after{
    content : "";
    position: absolute;
    top: 99%;
    left: 0; 
    width : 100%;
    height: 4px;
    background-color: var(--subBackgroundcolor);
}

/* 탭메뉴 모바일 css */
.Works_MB .Works-banner-tabs-menu{
    padding-right: 0;
}

.Works_MB .Works-banner-tabs-menu{
    border-bottom-width: 2px;
    border-bottom-color: var(--subBackgroundDarkcolor);
    font-size: 18px;
}

.Works_MB .Works-banner-tabs-menu > li:first-child{
    border-left: none;
}

.Works_MB .Works-banner-tabs-menu > li:last-child{
    border-right: none;
}

@media only screen and (max-width: 380px) {
    .Works_MB{
        zoom: 0.6;
    }
}

@media only screen and (max-width: 767px) {
    .Works_PC{
        display: none ;
    }
    .Works_Tab{
        display: none;
    }
    .Works_MB {
        display: flex;
    }
}

@media only screen and (min-width : 768px) and (max-width : 1199px) {
    .Works_PC{
        display: none ;
    }
    .Works_Tab{
        display: flex;
    }
    .Works_MB {
        display: none;
    }
}

@media only screen and (min-width: 1200px) {
    .Works_PC{
        display: flex ;
    }
    .Works_Tab{
        display: none;
    }
    .Works_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .Works_PC{
        zoom: 1.2;
    }
}

@media only screen and (min-width: 2561px){
    .Works_PC{
        zoom: 1.4;
    }
}