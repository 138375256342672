:root{
  --mainBackgroundcolor : #eeeeee;
  --subBackgroundcolor : #ffffff;
  --subBackgroundDarkcolor : #000000;
  --mainWidgetcolor : #d9d9d9;
  --mainWidgetcolor2 : #606060;
  --mainFontcolor : #373737;
  --mainDurationColor : #949494;
}

html, body{
  margin: 0;
  padding : 0;
  background-color: var(--mainBackgroundcolor);
  color : var(--mainFontcolor);
  position: relative;
  box-sizing: border-box;
}

.con{
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
}

.tab_con{
  max-width: 700px;
  margin: 0 auto;
}

a{
  color: inherit;
  text-decoration: none;
}

.Top-Menu-Fix {
  width: 100%;
  height: auto;
  position: sticky;
  top : 0;
  z-index: 997;
}

/* button */
.ButtonForm1{
  border-radius: 20px;
  background-color: var( --mainDurationColor);
  color: var(--mainBackgroundcolor);
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  font-weight: normal;
}

.ButtonForm1:hover{
  color: var(--mainFontcolor);
  border: 4px solid var(--mainFontcolor);
  font-weight: bold;
}

.ButtonForm2{
  border-radius: 20px;
  background-color: var( --mainWidgetcolor2);
  color: var(--mainBackgroundcolor);
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.Pin{
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
}