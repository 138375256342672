.About{
    font-family : 'ONE-Mobile-Title';
    background-color: var(--mainBackgroundcolor);
    color: var(--mainFontcolor);
}

.About_PC {
    margin-top: 50px;
    margin-bottom: 70px;
}

.About_PC .Title{
    font-size: 52px;
    font-weight: bold;
    margin: 80px 0 120px 0;
    position: relative;
}

.About_PC .Title::after{
    content: "";
    position: absolute;
    top : 120%; left: -15px; right: -15px;
    height: 6px;
    background-color:  var(--mainFontcolor);

}

.About_PC .Intro_Bar{
    height: 360px;
    font-size: 24px;
    border: 4px solid white;
    /* border: 2px solid var(--mainWidgetcolor); */
    background-color: var(--mainWidgetcolor);
    padding: 40px;
}

.About_PC .Intro_Bar p{
    color:  var(--mainFontcolor);
    font-weight: bold;
}
.About_PC .Intro_Bar i{
    color: white;
}

.About .About_PC a{
    cursor: pointer;
    color: var(--mainWidgetcolor2);
    transition: 1s;
    font-size: 20px;
}

.About .About_PC a:hover{
    font-weight: bold;
}

/* Tab */
.About_Tab {
    padding: 50px 30px 70px 30px;
}

.About_Tab .Title{
    font-size: 52px;
    font-weight: bold;
    margin: 80px 0 120px 0;
    position: relative;
}

.About_Tab .Title::after{
    content: "";
    position: absolute;
    top : 120%; left: -15px; right: -15px;
    height: 6px;
    background-color:  var(--mainFontcolor);

}

.About_Tab .Intro_Bar{
    min-height: 360px;
    font-size: 24px;
    border: 4px solid white;
    /* border: 2px solid var(--mainWidgetcolor); */
    background-color: var(--mainWidgetcolor);
    padding: 40px;
}

.About_Tab .Intro_Bar p{
    color:  var(--mainFontcolor);
    font-weight: bold;
}
.About_Tab .Intro_Bar i{
    color: white;
}


/* Mb */
.About_MB {
    padding: 50px 30px 70px 30px;
}

.About_MB .Title{
    font-size: 42px;
    font-weight: bold;
    margin: 20px 0 100px 0;
    position: relative;
}

.About_MB .Title::after{
    content: "";
    position: absolute;
    top : 120%; left: -15px; right: -15px;
    height: 6px;
    background-color:  var(--mainFontcolor);

}

.About_MB .Intro_Bar{
    min-height: 360px;
    font-size: 18px;
    border: 4px solid white;
    /* border: 2px solid var(--mainWidgetcolor); */
    background-color: var(--mainWidgetcolor);
    padding: 30px;
}

.About_MB .Intro_Bar p{
    color:  var(--mainFontcolor);
    font-weight: bold;
    text-align: center;
}
.About_MB .Intro_Bar i{
    color: white;
}

.About_MB .Intro_Bar i::before{
    display: flex;
    align-items: center;
    justify-content: center;
}


@media only screen and (max-width: 380px) {
    .About_MB{
        zoom: 0.6;
    }
}

@media only screen and (max-width: 767px) {
    .About_PC{
        display: none ;
    }
    .About_Tab{
        display: none;
    }
    .About_MB {
        display: flex;
    }
}

@media only screen and (min-width : 768px) and (max-width : 1199px) {
    .About_PC{
        display: none ;
    }
    .About_Tab{
        display: flex;
    }
    .About_MB {
        display: none;
    }
}

@media only screen and (min-width: 1200px) {
    .About_PC{
        display: flex ;
    }
    .About_Tab{
        display: none;
    }
    .About_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .About_PC{
        zoom: 1.2;
    }
}

@media only screen and (min-width: 2561px){
    .About_PC{
        zoom: 1.4;
    }
}