.Commission input, .Commission select, .Commission textarea{ 
    border: 2px solid var(--mainWidgetcolor2);
    border-radius: 5px;
    overflow: hidden;
    padding : 0 0 0 5px;
}

.Commission .CommissionForm{ 
    margin : 0 0px 30px 0px;
    padding: 40px 30px;
    border: 2px solid var(--mainWidgetcolor2);

}

.Commission .CommissionForm label{ 
    font-weight: bold;
    font-size: 18px;
    margin: 0 20px 0 0;
}

.Commission .CommissionPrint{
    padding: 30px 20px;
    border: 2px solid var(--mainWidgetcolor2);
    position: relative;
    margin : 0 0px 30px 0px;

}

.Commission .CommissionPrint i {
    position: absolute;
    top: 20px; right: 20px;
    cursor: pointer;
    font-size: 28px;
    padding: 20px;
    border-radius: 40px;
    background-color: rgba(0,0,0,0);
    cursor: pointer;
    transition: 1s;
}

.Commission .CommissionPrint .fa-clone {
    top: 20px; right: 100px;
}

.Commission .CommissionPrint .fa-file-pdf {
    top: 20px; right: 20px;
}

.Commission .CommissionPrint i:hover {
    
    background-color: var(--mainWidgetcolor);
}

.Commission .CommissionPrint p{
    font-weight: bold;
    font-size: 18px;
    margin: 0 10px 0 0;
}

.Commission .CommissionPrint .Print{
    padding: 0 20px 20px 20px;
}

.Commission .CommissionPrint .Pays{
    padding: 20px 20px 0 20px;
    border-top : 2px solid var(--mainWidgetcolor2);
}

.Commission .CommissionSend{
    padding: 30px 20px;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--mainWidgetcolor2);
}

.Commission .CommissionSend p{
    padding: 30px 0;
    color: var(--mainFontcolor);
    font-weight: bold;
    font-size: 20px;
}

@media only screen and (max-width: 767px) {
    .Commission .CommissionPrint .fa-clone {
        top : 10px; right: 10px;
    }
    
    .Commission .CommissionPrint .fa-file-pdf {
        display: none;
    }
}

/* 프린트 전용 스타일 */
@media print {
    body * {
        visibility: hidden;
    }

    #contentToPrint{
        width: 100vw;
        height: 100vh;
    }

    #contentToPrint, #contentToPrint * {
        visibility: visible;
    }

    #contentToPrint button, #contentToPrint a {
        visibility: hidden;
    }

    #contentToPrint {
        position: absolute;
        left: 0;
        top: 0;
    }
}