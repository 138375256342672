.Menu{
    font-family: 'ImcreSoojin';
    background-color: var(--mainBackgroundcolor);
    color: var(--mainFontcolor);
}

/* PC */
.Menu_PC {
    /* padding: 0 30px; */
    font-size: 24px;
    height: 100px;
}

.Menu_PC-left img {
    height: 80px;
}

.Menu_PC-right > li{
    padding: 0 20px;
}

/* 모바일 */
.Menu_MB {
    padding: 10px 30px;
    height: 100px;
    font-size: 24px;
}

.Menu_MB-left img {
    height: 70px;
}

.Menu_MB-right i{
    color : var(--mainWidgetcolor);
    font-size: 32px;
}

.Menu_MB-lists {
    position : absolute;
    top: 0;
    left: -80%;
    width: 80%;
    height: 100vh;
    background: var(--mainBackgroundcolor);
    transition: left 0.5s;
}

.Menu_MB-lists.Output {
    left:0%;
}

.Menu_MB-lists-menu{
    padding: 20px;
}

.Menu_MB-lists-menu > li{
    padding: 30px 0 20px 10px;    
    font-size: 20px;
}

.menuListline, .menuLine{
    background-color: var(--mainWidgetcolor);
}

@media only screen and (max-width: 380px) {
    .Menu_MB-left{
        zoom: 0.7;
    }

    /* .Menu_MB-right i{
        font-size: 24px;
    }*/

    .Menu_MB{
        padding: 10px 20px;
    } 
}

@media only screen and (max-width : 1199px) {
    .Menu_PC{
        display: none;
    }
    .Menu_MB {
        display: flex;
    }
}

@media only screen and (min-width: 1200px) {
    .Menu_PC{
        display: flex ;
    }
    .Menu_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .Menu_PC{
        width: 1440px;
    }
}

@media only screen and (min-width: 2561px){
    .Menu_PC{
        width: 1680px;
    }
}